import React from 'react';
import { 
  Box, 
  SimpleGrid, 
  Text, 
  Heading, 
  Icon, 
  Flex, 
  Badge,
  VStack,
  useColorModeValue
} from '@chakra-ui/react';
import { 
  FiCode, 
  FiLayers, 
  FiTool, 
  FiCpu
} from 'react-icons/fi';

const SkillsSection = () => {
  const skills = {
    languages: ['C++', 'Java', 'Python', 'JavaScript', 'SQL', 'HTML', 'CSS'],
    frameworks: ['Flask', 'Firebase', 'React', 'Vercel', 'GitHub', 'Azure'],
    software: ['VS Code', 'IntelliJ', 'PyCharm', 'Git', 'CLion']
  };

  // Badge colors
  const badgeBg = useColorModeValue("blue.100", "blue.900/20");
  const badgeColor = useColorModeValue("blue.700", "blue.400");
  const badgeBorder = useColorModeValue("blue.300", "blue.800");
  const textColor = useColorModeValue("gray.700", "gray.300");

  return (
    <Box 
      mt={16}
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <Box textAlign="center" mb={10}>
        <Badge 
          bg={badgeBg}
          color={badgeColor}
          px={6}
          py={2.5}
          borderRadius="full"
          fontSize="lg"
          textTransform="none"
          fontWeight="bold"
          borderWidth="1px"
          borderColor={badgeBorder}
          display="inline-flex"
          alignItems="center"
          mb={3}
          boxShadow="sm"
        >
          <Icon as={FiCpu} boxSize={5} mr={3} />
          Technical Skills
        </Badge>
        
        <Text
          color={textColor}
          maxW="2xl"
          mx="auto"
          fontSize="md"
        >
          My professional toolkit includes a diverse range of technologies
        </Text>
      </Box>
      
      <SimpleGrid 
        columns={{ base: 1, md: 3 }} 
        spacing={6}
        px={{ base: 0, md: 4 }}
      >
        <SkillCategory 
          icon={FiCode} 
          title="Languages" 
          skills={skills.languages} 
          color="blue"
        />
        <SkillCategory 
          icon={FiLayers} 
          title="Frameworks" 
          skills={skills.frameworks} 
          color="teal"
        />
        <SkillCategory 
          icon={FiTool} 
          title="Tools" 
          skills={skills.software} 
          color="purple"
        />
      </SimpleGrid>
    </Box>
  );
};

const SkillCategory = ({ icon, title, skills, color }) => {
  // Define all color mode values at the component level, not inside functions
  const baseColor = color || 'blue';
  
  // Colors for light/dark mode
  const bg = useColorModeValue(`${baseColor}.100`, `${baseColor}.900/30`);
  const border = useColorModeValue(`${baseColor}.300`, `${baseColor}.700`);
  const text = useColorModeValue(`${baseColor}.800`, `${baseColor}.300`);
  const iconBg = useColorModeValue(`${baseColor}.100`, `${baseColor}.900/50`);
  const iconBorder = useColorModeValue(`${baseColor}.300`, `${baseColor}.700`);
  const iconColor = useColorModeValue(`${baseColor}.700`, `${baseColor}.400`);
  const titleColor = useColorModeValue('gray.800', 'white');
  
  // Card background and border colors
  const cardBg = useColorModeValue('white', 'gray.800/30');
  const cardBorder = useColorModeValue(`${baseColor}.200`, 'gray.700');
  const cardShadow = useColorModeValue('0 4px 12px rgba(0, 0, 0, 0.05)', 'lg');
  
  return (
    <VStack 
      align="flex-start" 
      spacing={4}
      p={5}
      borderRadius="xl"
      bg={cardBg}
      backdropFilter="blur(12px)"
      borderWidth="1px"
      borderColor={cardBorder}
      transition="all 0.3s"
      boxShadow={cardShadow}
      _hover={{ 
        transform: 'translateY(-5px)', 
        boxShadow: useColorModeValue('0 8px 24px rgba(0, 0, 0, 0.1)', 'xl'),
        borderColor: border
      }}
    >
      <Flex
        w={10}
        h={10}
        align="center"
        justify="center"
        color={iconColor}
        bg={iconBg}
        borderRadius="lg"
        borderWidth="1px"
        borderColor={iconBorder}
      >
        <Icon as={icon} boxSize={5} />
      </Flex>
      
      <Heading as="h3" size="sm" color={titleColor} fontWeight="semibold">
        {title}
      </Heading>
      
      <Flex flexWrap="wrap" gap={2}>
        {skills.map((skill, index) => (
          <Badge
            key={index}
            fontSize="xs"
            bg={bg}
            color={text}
            borderWidth="1px"
            borderColor={border}
            borderRadius="full"
            px={2}
            py={0.5}
            _hover={{
              transform: 'translateY(-1px)',
              boxShadow: 'sm',
            }}
            transition="all 0.2s"
          >
            {skill}
          </Badge>
        ))}
      </Flex>
    </VStack>
  );
};

export default SkillsSection; 