import React, { useEffect } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './pages/About';
import { RootThemeProvider } from './components/ThemeProvider';

// Using our theme from theme.js and the new theme provider

function App() {
  // Add theme transition class to body on mount
  useEffect(() => {
    document.body.classList.add('theme-transition');
    
    // Always default to dark theme if not set
    const storedTheme = localStorage.getItem('theme') || 'dark';
    localStorage.setItem('theme', storedTheme);
    
    // Apply theme classes
    document.documentElement.classList.add(storedTheme);
    document.body.classList.add(storedTheme);
    
    // Set data-theme attribute for CSS variables
    document.documentElement.setAttribute('data-theme', storedTheme);
    
    return () => {
      document.body.classList.remove('theme-transition');
    };
  }, []);
  
  return (
    <RootThemeProvider>
      <AppContent />
    </RootThemeProvider>
  );
}

// Separate component to use hooks after theme provider is initialized
function AppContent() {
  const bgColor = useColorModeValue('gray.50', 'gray.950');
  
  return (
    <Box 
      bg={bgColor} 
      minHeight="100vh"
      className="flex flex-col"
    >
      <Navbar />
      <Box flex="1">
        <About />
      </Box>
      <Footer />
    </Box>
  );
}

export default App;