import * as React from "react";
import { cn } from "../../lib/utils";
import { useColorModeValue } from "@chakra-ui/react";

const Timeline = React.forwardRef(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={cn("space-y-16 relative", className)} {...props}>
      {children}
    </div>
  );
});
Timeline.displayName = "Timeline";

const TimelineItem = React.forwardRef(({ className, children, ...props }, ref) => {
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const borderHoverColor = useColorModeValue("blue.300", "blue.600");
  const dotBgColor = useColorModeValue("white", "gray.800");
  const dotBorderColor = useColorModeValue("blue.400", "blue.500");
  const innerDotBgColor = useColorModeValue("blue.500", "blue.400");
  const innerDotHoverBgColor = useColorModeValue("teal.500", "teal.400");
  const innerDotShadow = useColorModeValue(
    "0 0 8px rgba(59,130,246,0.5)",
    "0 0 10px rgba(59,130,246,0.7)"
  );
  const innerDotHoverShadow = useColorModeValue(
    "0 0 12px rgba(45,212,191,0.6)",
    "0 0 15px rgba(45,212,191,0.8)"
  );

  return (
    <div
      ref={ref}
      className={cn(
        "relative pl-10 border-l-2 transition-colors duration-300",
        className
      )}
      style={{
        borderLeftColor: borderColor,
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.borderLeftColor = borderHoverColor;
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.borderLeftColor = borderColor;
      }}
      {...props}
    >
      <span
        className="absolute left-[-9px] top-2 h-4 w-4 rounded-full z-10"
        style={{
          background: dotBgColor,
          border: `2px solid ${dotBorderColor}`,
          boxShadow: "0 0 0 4px rgba(255, 255, 255, 0.1)",
        }}
      />
      <span
        className="absolute left-[-5px] top-3 h-2 w-2 rounded-full z-20 transition-all duration-500"
        style={{
          background: innerDotBgColor,
          boxShadow: innerDotShadow,
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.background = innerDotHoverBgColor;
          e.currentTarget.style.boxShadow = innerDotHoverShadow;
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.background = innerDotBgColor;
          e.currentTarget.style.boxShadow = innerDotShadow;
        }}
      />
      {children}
    </div>
  );
});
TimelineItem.displayName = "TimelineItem";

const TimelineHeader = React.forwardRef(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "mb-3 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
});
TimelineHeader.displayName = "TimelineHeader";

const TimelineDate = React.forwardRef(({ className, children, fontSize, color, ...props }, ref) => {
  const bgColor = useColorModeValue("blue.50", "gray.800");
  const defaultTextColor = useColorModeValue("blue.600", "blue.300");
  const borderColor = useColorModeValue("blue.100", "gray.700");
  
  const textColor = color || defaultTextColor;
  const fontSizeValue = fontSize || "xs";

  return (
    <span
      ref={ref}
      className={cn(
        `text-${fontSizeValue} font-medium px-3 py-1.5 rounded-full border shadow-sm backdrop-blur-sm flex items-center justify-center whitespace-nowrap`,
        className
      )}
      style={{
        backgroundColor: bgColor,
        color: textColor,
        borderColor: borderColor,
      }}
      {...props}
    >
      {children}
    </span>
  );
});
TimelineDate.displayName = "TimelineDate";

const TimelineSubtitle = React.forwardRef(({ className, children, fontSize, color, ...props }, ref) => {
  const subtitleColor = useColorModeValue("blue.600", "blue.300");
  const fontSizeValue = fontSize || "sm";

  return (
    <span
      ref={ref}
      className={cn(
        `text-${fontSizeValue} font-medium flex items-center mb-3 mt-1`,
        className
      )}
      style={{
        color: color || subtitleColor,
      }}
      {...props}
    >
      {children}
    </span>
  );
});
TimelineSubtitle.displayName = "TimelineSubtitle";

const TimelineContent = React.forwardRef(({ className, children, ...props }, ref) => {
  const bgColor = useColorModeValue("gray.50", "gray.800/40");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const borderHoverColor = useColorModeValue("blue.200", "blue.700");
  const textColor = useColorModeValue("gray.700", "gray.300");
  const shadowColor = useColorModeValue(
    "0 4px 20px -12px rgba(0,0,0,0.1)",
    "0 4px 20px -12px rgba(0,0,0,0.5)"
  );
  const hoverShadowColor = useColorModeValue(
    "0 8px 30px -15px rgba(0,0,0,0.15)",
    "0 8px 30px -15px rgba(0,0,0,0.7)"
  );

  return (
    <div
      ref={ref}
      className={cn(
        "mt-3 text-sm leading-relaxed rounded-lg p-5 border transition-all duration-300 backdrop-blur-sm",
        className
      )}
      style={{
        backgroundColor: bgColor,
        borderColor: borderColor,
        color: textColor,
        boxShadow: shadowColor,
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.borderColor = borderHoverColor;
        e.currentTarget.style.boxShadow = hoverShadowColor;
        e.currentTarget.style.transform = "translateY(-2px)";
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.borderColor = borderColor;
        e.currentTarget.style.boxShadow = shadowColor;
        e.currentTarget.style.transform = "translateY(0)";
      }}
      {...props}
    >
      {children}
    </div>
  );
});
TimelineContent.displayName = "TimelineContent";

export {
  Timeline,
  TimelineItem,
  TimelineHeader,
  TimelineDate,
  TimelineSubtitle,
  TimelineContent,
};
