import React from "react";
import {
  Box,
  Text,
  Icon,
  Badge,
  Flex,
  useColorModeValue,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { FiBriefcase, FiBookOpen } from "react-icons/fi";

// Import the Timeline components
import {
  Timeline,
  TimelineItem,
  TimelineHeader,
  TimelineDate,
  TimelineSubtitle,
  TimelineContent,
} from "./ui/timeline";

const experiences = [
  {
    title: "Software Engineer Intern",
    company: "SupplyTiger",
    location: "Elizabethtown, PA",
    date: "May 2023 – Jul 2023",
    description: [
      "Led efforts to introduce AI and machine learning at SupplyTiger, improving operational efficiency.",
      "Developed full-stack solutions using HTML5, CSS3, Python, and JavaScript.",
      "Automated Amazon listing creation via RESTful API integrations.",
      "Applied data-driven marketing techniques using Helium 10 and Amazon advertising.",
      "Developed and launched unique product offerings, increasing sales through A/B testing and predictive analytics.",
    ],
    type: "work",
    skills: [
      "Python",
      "JavaScript",
      "RESTful APIs",
      "Machine Learning",
      "Data Analytics",
    ],
    icon: FiBriefcase,
  },
  {
    title: "Bachelor of Science in Computer Science",
    company: "Pennsylvania State University",
    location: "Harrisburg, PA",
    date: "Expected Graduation: Spring 2025",
    description: [
      "Honors: Dean's List, Bunton-Waller Scholarship recipient.",
      "Major coursework: Data Structures and Algorithms, Software Engineering, Database Systems, Web Development.",
      "Developed the Clarify Task Management mobile app as a senior capstone project.",
    ],
    type: "education",
    skills: [
      "Data Structures",
      "Algorithms",
      "Software Engineering",
      "Database Systems",
      "Mobile Development",
    ],
    icon: FiBookOpen,
  },
];

const ExperienceSection = () => {
  // Section badge colors
  const badgeBg = useColorModeValue("blue.100", "blue.900/20");
  const badgeColor = useColorModeValue("blue.700", "blue.400");
  const badgeBorder = useColorModeValue("blue.300", "blue.700");
  
  // Heading and text colors
  const subtitleColor = useColorModeValue("gray.700", "gray.300");
  const keySkillsColor = useColorModeValue("gray.700", "gray.400");

  // Work badge colors
  const workBadgeBg = useColorModeValue("blue.100", "blue.900/30");
  const workBadgeColor = useColorModeValue("blue.700", "blue.400");
  const workBadgeBorder = useColorModeValue("blue.300", "blue.700");

  // Education badge colors
  const educBadgeBg = useColorModeValue("purple.100", "purple.900/30");
  const educBadgeColor = useColorModeValue("purple.700", "purple.400");
  const educBadgeBorder = useColorModeValue("purple.300", "purple.700");

  // Skills badge colors
  const skillBadgeBg = useColorModeValue("gray.100", "gray.800");
  const skillBadgeColor = useColorModeValue("gray.800", "gray.300");
  const skillBadgeBorder = useColorModeValue("gray.300", "gray.700");

  // Description text color
  const descriptionTextColor = useColorModeValue("gray.800", "gray.300");

  // Company and location color
  const companyColor = useColorModeValue("blue.700", "blue.300");
  
  // Divider color
  const dividerColor = useColorModeValue("gray.300", "gray.700");

  return (
    <Box mt={20} mb={16} data-aos="fade-up" data-aos-duration="1000">
      <Box textAlign="center" mb={16}>
        <Badge
          bg={badgeBg}
          color={badgeColor}
          px={6}
          py={2.5}
          borderRadius="full"
          fontSize="lg"
          textTransform="none"
          fontWeight="bold"
          borderWidth="1px"
          borderColor={badgeBorder}
          display="inline-flex"
          alignItems="center"
          mb={4}
          boxShadow="sm"
        >
          <Icon as={FiBriefcase} boxSize={5} mr={3} />
          Professional Journey
        </Badge>

        <Text
          color={subtitleColor}
          maxW="2xl"
          mx="auto"
          fontSize={{ base: "md", md: "lg" }}
        >
          My career path and educational background
        </Text>
      </Box>

      <Box maxW="4xl" mx="auto" px={{ base: 4, md: 8 }}>
        <VStack spacing={10} align="stretch">
          {/* Work Experience Section */}
          <Box>
            <Badge
              bg={workBadgeBg}
              color={workBadgeColor}
              px={6}
              py={2.5}
              borderRadius="full"
              fontSize="md"
              textTransform="none"
              fontWeight="bold"
              borderWidth="1px"
              borderColor={workBadgeBorder}
              display="inline-flex"
              alignItems="center"
              mb={6}
              boxShadow="sm"
            >
              <Icon as={FiBriefcase} boxSize={5} mr={3} />
              Work Experience
            </Badge>
            
            <Timeline>
              {experiences
                .filter(exp => exp.type === "work")
                .map((exp, index) => (
                  <TimelineItem key={index} className="timeline-item pb-12">
                    <TimelineHeader>
                      <TimelineDate fontSize="sm" color={subtitleColor}>
                        {exp.date}
                      </TimelineDate>
                    </TimelineHeader>

                    <TimelineSubtitle fontSize="sm" color={companyColor}>
                      <Icon as={exp.icon} mr={2} />
                      {exp.company} • {exp.location}
                    </TimelineSubtitle>

                    <TimelineContent className="timeline-content">
                      <VStack align="stretch" spacing={4}>
                        <ul className="space-y-3 list-none pl-0">
                          {exp.description.map((desc, i) => (
                            <li key={i} className="flex items-start gap-3">
                              <span className="inline-flex mt-1.5 h-2 w-2 rounded-full bg-gradient-to-r from-blue-400 to-teal-400 flex-shrink-0" />
                              <span className="text-sm" style={{ color: descriptionTextColor }}>
                                {desc}
                              </span>
                            </li>
                          ))}
                        </ul>

                        <Box mt={4}>
                          <Text
                            fontSize="xs"
                            fontWeight="semibold"
                            color={keySkillsColor}
                            mb={3}
                            letterSpacing="wide"
                            textTransform="uppercase"
                          >
                            Key Skills
                          </Text>
                          <Flex gap={2} flexWrap="wrap">
                            {exp.skills.map((skill, i) => (
                              <Badge
                                key={i}
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="xs"
                                fontWeight="medium"
                                bg={skillBadgeBg}
                                color={skillBadgeColor}
                                borderWidth="1px"
                                borderColor={skillBadgeBorder}
                                _hover={{
                                  bg: "blue.50",
                                  color: "blue.600",
                                  borderColor: "blue.200",
                                  transform: "translateY(-1px)",
                                  boxShadow: "sm",
                                }}
                                transition="all 0.2s ease"
                              >
                                {skill}
                              </Badge>
                            ))}
                          </Flex>
                        </Box>
                      </VStack>
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          </Box>
          
          <Divider borderColor={dividerColor} my={8} />
          
          {/* Education Section */}
          <Box mt={8}>
            <Badge
              bg={educBadgeBg}
              color={educBadgeColor}
              px={6}
              py={2.5}
              borderRadius="full"
              fontSize="md"
              textTransform="none"
              fontWeight="bold"
              borderWidth="1px"
              borderColor={educBadgeBorder}
              display="inline-flex"
              alignItems="center"
              mb={6}
              boxShadow="sm"
            >
              <Icon as={FiBookOpen} boxSize={5} mr={3} />
              Education
            </Badge>
            
            <Timeline>
              {experiences
                .filter(exp => exp.type === "education")
                .map((exp, index) => (
                  <TimelineItem key={index} className="timeline-item pb-12">
                    <TimelineHeader>
                      <TimelineDate fontSize="sm" color={subtitleColor}>
                        {exp.date}
                      </TimelineDate>
                    </TimelineHeader>

                    <TimelineSubtitle fontSize="sm" color={companyColor}>
                      <Icon as={exp.icon} mr={2} />
                      {exp.company} • {exp.location}
                    </TimelineSubtitle>

                    <TimelineContent className="timeline-content">
                      <VStack align="stretch" spacing={4}>
                        <ul className="space-y-3 list-none pl-0">
                          {exp.description.map((desc, i) => (
                            <li key={i} className="flex items-start gap-3">
                              <span className="inline-flex mt-1.5 h-2 w-2 rounded-full bg-gradient-to-r from-purple-400 to-pink-400 flex-shrink-0" />
                              <span className="text-sm" style={{ color: descriptionTextColor }}>
                                {desc}
                              </span>
                            </li>
                          ))}
                        </ul>

                        <Box mt={4}>
                          <Text
                            fontSize="xs"
                            fontWeight="semibold"
                            color={keySkillsColor}
                            mb={3}
                            letterSpacing="wide"
                            textTransform="uppercase"
                          >
                            Key Skills
                          </Text>
                          <Flex gap={2} flexWrap="wrap">
                            {exp.skills.map((skill, i) => (
                              <Badge
                                key={i}
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="xs"
                                fontWeight="medium"
                                bg={skillBadgeBg}
                                color={skillBadgeColor}
                                borderWidth="1px"
                                borderColor={skillBadgeBorder}
                                _hover={{
                                  bg: "purple.50",
                                  color: "purple.600",
                                  borderColor: "purple.200",
                                  transform: "translateY(-1px)",
                                  boxShadow: "sm",
                                }}
                                transition="all 0.2s ease"
                              >
                                {skill}
                              </Badge>
                            ))}
                          </Flex>
                        </Box>
                      </VStack>
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};

export default ExperienceSection;
