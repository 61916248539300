import { Spinner, Text, VStack, Flex } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
`;

const LoadingState = () => (
  <Flex 
    justifyContent="center" 
    alignItems="center" 
    height="100vh" 
    width="100%"
    bg="gray.950"
    position="fixed"
    top="0"
    left="0"
    zIndex="9999"
  >
    <VStack spacing={6}>
      <Spinner 
        size="xl" 
        thickness="4px"
        speed="0.8s"
        color="blue.400" 
        emptyColor="gray.700"
      />
      <Text 
        color="gray.300" 
        fontWeight="medium"
        fontSize="md"
        animation={`${pulse} 2s infinite ease-in-out`}
        letterSpacing="wide"
      >
        Loading content...
      </Text>
    </VStack>
  </Flex>
);

export default LoadingState; 