import React from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  Flex,
  Icon,
  useColorModeValue,
  Container,
  Badge,
  HStack,
  Center,
  Button,
} from "@chakra-ui/react";
import { FiCode, FiGithub, FiExternalLink, FiLayers } from "react-icons/fi";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const ProjectCard = ({
  title,
  description,
  imageUrls,
  link,
  github,
  techStack,
}) => {
  const techStackArray = techStack.split(", ");

  // Chakra UI color values
  const cardBg = useColorModeValue("gray.50", "gray.800");
  const cardBorder = useColorModeValue("gray.200", "gray.700");
  const cardHoverBorder = useColorModeValue("blue.300", "blue.600");
  const titleColor = useColorModeValue("gray.800", "white");
  const descColor = useColorModeValue("gray.600", "gray.300");
  const badgeBg = useColorModeValue("blue.50", "blue.900/30");
  const badgeColor = useColorModeValue("blue.600", "blue.400");
  const iconBg = useColorModeValue("blue.50", "blue.900/30");
  const iconColor = useColorModeValue("blue.600", "blue.400");
  const buttonHoverBg = useColorModeValue("blue.50", "blue.900");

  return (
    <MotionBox
      whileHover={{ y: -5, boxShadow: "xl" }}
      transition={{ duration: 0.3 }}
      borderWidth="1px"
      borderColor={cardBorder}
      borderRadius="xl"
      overflow="hidden"
      bg={cardBg}
      boxShadow="md"
      height="100%"
      display="flex"
      flexDirection="column"
      _hover={{ 
        borderColor: cardHoverBorder 
      }}
    >
      {/* Project Image */}
      <Box position="relative">
        <Box
          as="img"
          src={imageUrls[0]}
          alt={title}
          width="100%"
          height="220px"
          objectFit="cover"
          transition="transform 0.5s ease"
          _groupHover={{ transform: "scale(1.05)" }}
        />
        
        {/* Overlay gradient */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 50%)"
        />
        
        {/* Tech stack badges */}
        <Flex 
          position="absolute" 
          bottom={3} 
          left={3} 
          right={3}
          flexWrap="wrap"
          gap={2}
        >
          {techStackArray.slice(0, 3).map((tech, index) => (
            <Badge
              key={index}
              px={2}
              py={1}
              borderRadius="full"
              fontSize="xs"
              fontWeight="medium"
              bg={badgeBg}
              color={badgeColor}
              backdropFilter="blur(4px)"
            >
              {tech}
            </Badge>
          ))}
          {techStackArray.length > 3 && (
            <Badge
              px={2}
              py={1}
              borderRadius="full"
              fontSize="xs"
              fontWeight="medium"
              bg="gray.700/50"
              color="gray.300"
              backdropFilter="blur(4px)"
            >
              +{techStackArray.length - 3}
            </Badge>
          )}
        </Flex>
      </Box>

      {/* Content */}
      <VStack p={5} align="start" spacing={4} flex="1" justify="space-between">
        <Box>
          <Flex align="center" mb={2}>
            <Box
              p={2}
              borderRadius="md"
              bg={iconBg}
              color={iconColor}
              mr={3}
            >
              <Icon as={FiLayers} boxSize={4} />
            </Box>
            <Heading as="h3" size="md" color={titleColor} fontWeight="bold" lineHeight="tight">
              {title}
            </Heading>
          </Flex>
          
          <Text color={descColor} fontSize="sm" noOfLines={3} lineHeight="tall">
            {description}
          </Text>
        </Box>

        {/* Action buttons */}
        <HStack spacing={3} width="100%">
          {link && (
            <Button
              size="sm"
              colorScheme="blue"
              leftIcon={<FiExternalLink />}
              onClick={() => window.open(link, '_blank')}
              flex="1"
              borderRadius="md"
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "md"
              }}
            >
              Demo
            </Button>
          )}
          {github && (
            <Button
              size="sm"
              variant="outline"
              colorScheme="blue"
              leftIcon={<FiGithub />}
              onClick={() => window.open(github, '_blank')}
              flex="1"
              borderRadius="md"
              _hover={{
                bg: buttonHoverBg,
                transform: "translateY(-2px)",
                boxShadow: "md"
              }}
            >
              Code
            </Button>
          )}
        </HStack>
      </VStack>
    </MotionBox>
  );
};

const ProjectsSection = ({ projects }) => {
  // Color mode values
  const badgeBg = useColorModeValue("blue.100", "blue.900/20");
  const badgeColor = useColorModeValue("blue.700", "blue.400");
  const badgeBorder = useColorModeValue("blue.300", "blue.800");
  const subtitleColor = useColorModeValue("gray.700", "gray.300");
  const sectionBg = useColorModeValue("gray.50", "transparent");

  return (
    <Box as="section" py={20} id="projects" bg={sectionBg}>
      <Container maxW="7xl">
        <VStack spacing={8} mb={12} textAlign="center">
          {/* Featured Projects Badge (reduced height) */}
          <Center w="100%" mb={8}>
            <Badge
              bg={badgeBg}
              color={badgeColor}
              px={6}
              py={2.5}
              borderRadius="full"
              fontSize="lg"
              textTransform="none"
              fontWeight="bold"
              borderWidth="1px"
              borderColor={badgeBorder}
              display="inline-flex"
              alignItems="center"
              boxShadow="sm"
            >
              <Icon as={FiCode} boxSize={5} mr={3} />
              Featured Projects
            </Badge>
          </Center>

          <Text
            color={subtitleColor}
            maxW="3xl"
            mx="auto"
            textAlign="center"
            fontSize={{ base: "lg", md: "xl" }}
            lineHeight="tall"
            mb={12}
          >
            A collection of my most significant work showcasing my skills and
            experience.
          </Text>
        </VStack>

        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacing={8}
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default ProjectsSection;
