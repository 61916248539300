import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { 
  Box, 
  Image, 
  Flex,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  VStack,
  useColorModeValue,
  Text
} from '@chakra-ui/react';
import { FiSun, FiMoon, FiMenu, FiUser, FiCode, FiMail } from 'react-icons/fi';
import { useTheme } from './ThemeProvider';
import { Button } from './ui/button';
import { cn } from '../lib/utils';

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrolled, setScrolled] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const [logoLoaded, setLogoLoaded] = useState(false);
  const logoRef = useRef(null);
  
  // Preload both logos to prevent flickering
  useEffect(() => {
    // Use the native JavaScript Image constructor
    const lightLogo = new window.Image();
    lightLogo.src = '/LogoLight.png';
    
    const darkLogo = new window.Image();
    darkLogo.src = '/LogoDark.png';
    
    Promise.all([
      new Promise(resolve => { lightLogo.onload = resolve; }),
      new Promise(resolve => { darkLogo.onload = resolve; })
    ]).then(() => {
      setLogoLoaded(true);
    }).catch(() => {
      // If images fail to load, still set logoLoaded to true to prevent blocking the UI
      setLogoLoaded(true);
    });
  }, []);
  
  // Get the current logo based on theme
  const logo = theme === 'light' ? '/LogoLight.png' : '/LogoDark.png';

  const navItems = [
    { name: 'About Me', icon: FiUser },
    { name: 'Projects', icon: FiCode },
    { name: 'Contact', icon: FiMail }
  ];

  // Color mode values
  const navBg = useColorModeValue(
    scrolled ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.7)',
    scrolled ? 'rgba(10, 10, 12, 0.9)' : 'rgba(10, 10, 12, 0.7)'
  );
  const navBorder = useColorModeValue(
    scrolled ? 'gray.200' : 'transparent',
    scrolled ? 'gray.800' : 'transparent'
  );
  const navShadow = useColorModeValue(
    scrolled ? 'sm' : 'none',
    scrolled ? 'dark-lg' : 'none'
  );
  const buttonHoverBg = useColorModeValue('gray.100', 'gray.800');
  const buttonTextColor = useColorModeValue('gray.700', 'gray.300');
  const buttonHoverTextColor = useColorModeValue('gray.900', 'white');
  const drawerBg = useColorModeValue('white', 'gray.900');
  const drawerBorder = useColorModeValue('gray.200', 'gray.800');
  const drawerHeaderColor = useColorModeValue('gray.800', 'white');
  const logoTextGradient = useColorModeValue(
    "linear(to-r, blue.600, teal.600)",
    "linear(to-r, blue.400, teal.400)"
  );

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleThemeToggle = () => {
    // Add a small delay to prevent flickering
    setTimeout(() => {
      toggleTheme();
    }, 50);
  };

  return (
    <Box
      as="nav"
      position="fixed"
      top="0"
      zIndex="1000"
      width="100%"
      backdropFilter="blur(12px)"
      bg={navBg}
      borderBottom="1px solid"
      borderColor={navBorder}
      boxShadow={navShadow}
      transition="all 0.3s ease"
      py={scrolled ? 2 : 4}
      className={cn(
        "z-50 border-b backdrop-blur-md supports-[backdrop-filter]:bg-background/60",
        scrolled ? "py-2" : "py-4"
      )}
    >
      <Flex 
        justify="space-between" 
        align="center" 
        maxW="7xl" 
        mx="auto" 
        px={{ base: 4, md: 6 }}
      >
        <RouterLink to="/">
          <Flex 
            align="center" 
            gap={2}
            transition="transform 0.3s ease"
            _hover={{ transform: 'scale(1.05)' }}
          >
            <Image 
              ref={logoRef}
              src={logo} 
              alt="Portfolio Logo" 
              height="40px"
              opacity={logoLoaded ? 1 : 0}
              transition="opacity 0.3s ease"
            />
            <Text 
              fontWeight="bold" 
              fontSize="lg"
              display={{ base: 'none', md: 'block' }}
              bgGradient={logoTextGradient}
              bgClip="text"
            >
            </Text>
          </Flex>
        </RouterLink>

        {/* Desktop Navigation */}
        <Flex display={{ base: 'none', md: 'flex' }} gap={3} align="center">
          {navItems.map((item, index) => (
            <ScrollLink 
              key={index}
              to={item.name.toLowerCase().replace(' ', '')} 
              smooth={true} 
              duration={500}
            >
              <Button
                variant="ghost"
                size="sm"
                className={cn(
                  "px-4 py-2 rounded-full transition-all duration-300",
                  "hover:bg-gray-100 dark:hover:bg-gray-800"
                )}
                color={buttonTextColor}
                _hover={{ 
                  bg: buttonHoverBg,
                  color: buttonHoverTextColor,
                  transform: 'translateY(-2px)'
                }}
              >
                <Box as={item.icon} mr={2} />
                {item.name}
              </Button>
            </ScrollLink>
          ))}
          
          <Box 
            ml={2} 
            p={1} 
            borderRadius="full" 
            bg={useColorModeValue("gray.100", "gray.800")}
          >
            <IconButton
              onClick={handleThemeToggle}
              aria-label="Toggle color mode"
              icon={theme === 'light' ? <FiMoon /> : <FiSun />}
              size="sm"
              isRound
              variant="ghost"
              color={buttonTextColor}
              _hover={{ 
                bg: buttonHoverBg,
                color: buttonHoverTextColor,
                transform: 'rotate(15deg)'
              }}
              transition="all 0.3s ease"
            />
          </Box>
        </Flex>

        {/* Mobile Navigation Toggle */}
        <IconButton
          aria-label="Open menu"
          icon={<FiMenu />}
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="ghost"
          isRound
          color={buttonTextColor}
          _hover={{ bg: buttonHoverBg }}
          className="md:hidden"
        />
      </Flex>

      {/* Mobile Navigation Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
        <DrawerOverlay backdropFilter="blur(12px)" bg={useColorModeValue("rgba(255, 255, 255, 0.6)", "rgba(10, 10, 12, 0.6)")} />
        <DrawerContent 
          bg={drawerBg} 
          borderLeft="1px solid" 
          borderColor={drawerBorder}
          className="border-l"
        >
          <DrawerCloseButton color={buttonTextColor} className="mt-2 mr-2" />
          <DrawerHeader 
            borderBottomWidth="1px" 
            borderColor={drawerBorder}
            color={drawerHeaderColor}
            fontWeight="bold"
            py={4}
          >
            <Flex align="center" gap={2}>
              <Image 
                src={logo} 
                alt="Logo" 
                height="30px" 
                opacity={logoLoaded ? 1 : 0}
              />
              <Text>Menu</Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody py={6}>
            <VStack spacing={4} align="stretch">
              {navItems.map((item, index) => (
                <ScrollLink 
                  key={index}
                  to={item.name.toLowerCase().replace(' ', '')} 
                  smooth={true} 
                  duration={500}
                  onClick={onClose}
                >
                  <Button
                    variant="ghost"
                    className="w-full justify-start rounded-lg py-3"
                    color={buttonTextColor}
                    _hover={{ 
                      bg: buttonHoverBg,
                      color: buttonHoverTextColor
                    }}
                    leftIcon={<Box as={item.icon} />}
                  >
                    {item.name}
                  </Button>
                </ScrollLink>
              ))}
              
              <Box pt={2} mt={2} borderTopWidth="1px" borderColor={drawerBorder}>
                <Button
                  onClick={() => {
                    handleThemeToggle();
                    onClose();
                  }}
                  variant="outline"
                  className="w-full justify-start rounded-lg py-3 mt-2"
                  leftIcon={theme === 'light' ? <FiMoon /> : <FiSun />}
                  color={buttonTextColor}
                  borderColor={drawerBorder}
                  _hover={{ 
                    bg: buttonHoverBg,
                    color: buttonHoverTextColor
                  }}
                >
                  {theme === 'light' ? 'Dark' : 'Light'} Mode
                </Button>
              </Box>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navbar;