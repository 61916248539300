import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  fonts: {
    heading: '"Inter", system-ui, sans-serif',
    body: '"Inter", system-ui, sans-serif',
  },
  colors: {
    // Enhanced color system with better contrast
    gray: {
      950: '#0a0a0c', // True black for dark mode background
      900: '#121218', // Slightly lighter for cards in dark mode
      850: '#18181f', // For hover states in dark mode
      800: '#1e1e2a', // For borders in dark mode
      700: '#2a2a3a',
      600: '#3a3a4a',
      500: '#4e4e5f',
      400: '#6e6e82',
      300: '#8e8ea0',
      200: '#aeaec0',
      100: '#d0d0e0',
      50: '#f7f7fb', // Light background - slightly warmer
    },
    blue: {
      900: '#1a365d',
      800: '#1e429f',
      700: '#1a56db',
      600: '#2563eb', // Primary blue
      500: '#3b82f6',
      400: '#60a5fa',
      300: '#93c5fd',
      200: '#bfdbfe',
      100: '#dbeafe',
      50: '#e6f0ff', // Slightly richer blue tint for light mode
    },
    teal: {
      900: '#134e4a',
      800: '#115e59',
      700: '#0f766e',
      600: '#0d9488',
      500: '#14b8a6',
      400: '#2dd4bf',
      300: '#5eead4',
      200: '#99f6e4',
      100: '#ccfbf1',
      50: '#e6faf7', // Slightly richer teal tint for light mode
    },
    green: {
      900: '#064e3b',
      800: '#065f46',
      700: '#047857',
      600: '#059669',
      500: '#10b981',
      400: '#34d399',
      300: '#6ee7b7',
      200: '#a7f3d0',
      100: '#d1fae5',
      50: '#e6f7f0', // Slightly richer green tint for light mode
    },
    amber: {
      900: '#78350f',
      800: '#92400e',
      700: '#b45309',
      600: '#d97706',
      500: '#f59e0b',
      400: '#fbbf24',
      300: '#fcd34d',
      200: '#fde68a',
      100: '#fef3c7',
      50: '#fff8e6', // Slightly richer amber tint for light mode
    },
    red: {
      900: '#7f1d1d',
      800: '#991b1b',
      700: '#b91c1c',
      600: '#dc2626',
      500: '#ef4444',
      400: '#f87171',
      300: '#fca5a5',
      200: '#fecaca',
      100: '#fee2e2',
      50: '#fff0f0', // Slightly richer red tint for light mode
    },
    purple: {
      900: '#4a1d96',
      800: '#5b21b6',
      700: '#6d28d9',
      600: '#7c3aed',
      500: '#8b5cf6',
      400: '#a78bfa',
      300: '#c4b5fd',
      200: '#ddd6fe',
      100: '#ede9fe',
      50: '#f3efff', // Slightly richer purple tint for light mode
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? 'gray.950' : 'gray.50',
        color: props.colorMode === 'dark' ? 'gray.100' : 'gray.700', // Darker text for better contrast
      },
    }),
  },
  components: {
    Button: {
      baseStyle: {
        _hover: { transform: 'translateY(-2px)' },
        transition: 'all 0.3s ease',
        fontWeight: 'medium',
        borderRadius: 'lg',
        _focus: {
          boxShadow: 'outline',
        },
      },
      variants: {
        primary: {
          bg: 'linear-gradient(90deg, var(--chakra-colors-blue-600), var(--chakra-colors-teal-500))',
          color: 'white',
          _hover: {
            bg: 'linear-gradient(90deg, var(--chakra-colors-blue-700), var(--chakra-colors-teal-600))',
            transform: 'translateY(-2px)',
            boxShadow: '0 10px 20px -10px rgba(66, 153, 225, 0.5)',
          },
          _active: {
            bg: 'linear-gradient(90deg, var(--chakra-colors-blue-800), var(--chakra-colors-teal-700))',
            transform: 'translateY(0)',
          },
        },
        secondary: {
          bg: 'transparent',
          color: 'gray.300',
          border: '1px solid',
          borderColor: 'gray.700',
          _hover: {
            bg: 'gray.800',
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 12px -2px rgba(0, 0, 0, 0.3)',
          },
          _active: {
            bg: 'gray.700',
            transform: 'translateY(0)',
          },
          _light: {
            color: 'gray.700', // Darker text for better contrast
            borderColor: 'gray.300',
            _hover: {
              bg: 'gray.100',
              boxShadow: '0 4px 12px -2px rgba(0, 0, 0, 0.1)',
            },
            _active: {
              bg: 'gray.200',
            },
          },
        },
        ghost: {
          bg: 'transparent',
          _hover: {
            bg: 'gray.800/50',
            transform: 'translateY(-2px)',
          },
          _active: {
            bg: 'gray.700/50',
            transform: 'translateY(0)',
          },
          _light: {
            _hover: {
              bg: 'gray.100',
            },
            _active: {
              bg: 'gray.200',
            },
          },
        },
        destructive: {
          bg: 'red.900/20',
          borderColor: 'red.800',
          color: 'red.400',
          _hover: {
            bg: 'red.900/30',
            transform: 'translateY(-2px)',
          },
          _active: {
            bg: 'red.900/40',
            transform: 'translateY(0)',
          },
          _light: {
            bg: 'red.50',
            borderColor: 'red.200',
            color: 'red.600',
            _hover: {
              bg: 'red.100',
            },
            _active: {
              bg: 'red.200',
            },
          },
        },
      },
      sizes: {
        xs: {
          fontSize: 'xs',
          px: 2,
          py: 1,
          h: 8,
          minW: 8,
        },
        sm: {
          fontSize: 'sm',
          px: 3,
          py: 2,
          h: 9,
          minW: 9,
        },
        md: {
          fontSize: 'md',
          px: 4,
          py: 2,
          h: 10,
          minW: 10,
        },
        lg: {
          fontSize: 'md',
          px: 6,
          py: 3,
          h: 12,
          minW: 12,
        },
      },
      defaultProps: {
        variant: 'primary',
        size: 'md',
      },
    },
    Link: {
      baseStyle: {
        _hover: { textDecoration: 'none' },
        transition: 'all 0.3s ease',
        fontWeight: 'medium',
      },
    },
    Card: {
      baseStyle: (props) => ({
        bg: props.colorMode === 'dark' ? 'gray.900' : 'white',
        borderRadius: 'xl',
        borderWidth: '1px',
        borderColor: props.colorMode === 'dark' ? 'gray.800' : 'gray.200',
        overflow: 'hidden',
        transition: 'all 0.3s ease',
        boxShadow: props.colorMode === 'dark' ? 'md' : '0 4px 12px rgba(0, 0, 0, 0.05)',
        _hover: {
          transform: 'translateY(-4px)',
          boxShadow: props.colorMode === 'dark' ? 'lg' : '0 8px 24px rgba(0, 0, 0, 0.1)',
          borderColor: props.colorMode === 'dark' ? 'gray.700' : 'blue.200',
        },
      }),
    },
    Badge: {
      baseStyle: {
        borderRadius: 'full',
        textTransform: 'normal',
        fontWeight: 'medium',
      },
      variants: {
        subtle: (props) => ({
          bg: props.colorMode === 'dark' ? `${props.colorScheme}.900/30` : `${props.colorScheme}.100`,
          color: props.colorMode === 'dark' ? `${props.colorScheme}.300` : `${props.colorScheme}.800`,
        }),
        solid: (props) => ({
          bg: props.colorMode === 'dark' ? `${props.colorScheme}.600` : `${props.colorScheme}.500`,
          color: 'white',
        }),
        outline: (props) => ({
          borderColor: props.colorMode === 'dark' ? `${props.colorScheme}.700` : `${props.colorScheme}.400`,
          color: props.colorMode === 'dark' ? `${props.colorScheme}.300` : `${props.colorScheme}.700`,
        }),
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: 'bold',
        lineHeight: 'shorter',
        letterSpacing: 'tight',
      },
    },
    Text: {
      baseStyle: {
        lineHeight: 'tall',
      },
    },
  },
}); 