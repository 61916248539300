import React, { useEffect, useRef } from 'react';
import { Text, useColorModeValue } from '@chakra-ui/react';

const TypedText = ({ strings = [
  'a Penn State CS Student',
  'a Full-Stack Developer',
  'an AI Enthusiast',
  'a Problem Solver',
  'a Coffee Addict ☕',
] }) => {
  const textRef = useRef(null);
  const cursorRef = useRef(null);
  const timeoutRef = useRef(null);
  const gradientColors = useColorModeValue(
    "linear(to-r, blue.600, teal.600)",
    "linear(to-r, blue.400, teal.400)"
  );
  const cursorColor = useColorModeValue("teal.600", "teal.400");

  useEffect(() => {
    let currentIndex = 0;
    let charIndex = 0;
    let isDeleting = false;

    const type = () => {
      if (!textRef.current) return;

      const currentText = strings[currentIndex];
      
      if (isDeleting) {
        textRef.current.textContent = currentText.substring(0, charIndex);
        charIndex--;
      } else {
        textRef.current.textContent = currentText.substring(0, charIndex + 1);
        charIndex++;
      }

      let typeSpeed = 120;

      if (isDeleting) {
        typeSpeed = 40;
      }

      if (!isDeleting && charIndex === currentText.length) {
        typeSpeed = 2000;
        isDeleting = true;
      } else if (isDeleting && charIndex === 0) {
        isDeleting = false;
        currentIndex = (currentIndex + 1) % strings.length;
        typeSpeed = 500;
      }

      timeoutRef.current = setTimeout(type, typeSpeed);
    };

    // Start the cursor blinking
    if (cursorRef.current) {
      cursorRef.current.classList.add('typing-cursor');
    }

    type();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [strings]);

  return (
    <Text as="span" position="relative" display="inline-flex" alignItems="center">
      <Text
        as="span"
        ref={textRef}
        bgGradient={gradientColors}
        bgClip="text"
        fontWeight="bold"
      />
      <Text
        as="span"
        ref={cursorRef}
        display="inline-block"
        width="2px"
        height="1.2em"
        marginLeft="2px"
        bg={cursorColor}
        position="relative"
        top="0.1em"
        sx={{
          '@keyframes blink': {
            '0%': { opacity: 1 },
            '50%': { opacity: 0 },
            '100%': { opacity: 1 }
          },
          '&.typing-cursor': {
            animation: 'blink 1s infinite'
          }
        }}
      />
    </Text>
  );
};

export default TypedText; 