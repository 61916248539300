import React, { createContext, useContext, useEffect, useState } from 'react';
import { ChakraProvider, useColorMode } from '@chakra-ui/react';
import { theme } from '../theme';

const ThemeContext = createContext({
  theme: 'dark',
  toggleTheme: () => {},
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [theme, setTheme] = useState('dark'); // Default to dark
  const [isInitialized, setIsInitialized] = useState(false);

  // Initial setup effect - runs only once
  useEffect(() => {
    // Add transition styles to the document
    const style = document.createElement('style');
    style.innerHTML = `
      body, html {
        transition: background-color 0.3s ease, color 0.3s ease;
      }
      * {
        transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
      }
    `;
    document.head.appendChild(style);

    // Get initial theme from localStorage if available
    const storedTheme = localStorage.getItem('theme') || 'dark'; // Default to dark if not set
    
    // Set the theme based on stored value or default to dark
    if ((storedTheme === 'light' && colorMode === 'dark') || 
        (storedTheme === 'dark' && colorMode === 'light')) {
      // Delay the toggle to prevent flickering
      setTimeout(() => {
        toggleColorMode();
      }, 0);
    }
    
    // Always store the theme
    localStorage.setItem('theme', storedTheme);
    
    // Mark initialization as complete
    setIsInitialized(true);
    
    return () => {
      document.head.removeChild(style);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array as this should only run once on mount

  // Effect to handle theme changes
  useEffect(() => {
    // Only update when initialized to prevent flickering
    if (!isInitialized) return;
    
    // Set theme based on colorMode
    setTheme(colorMode);
    
    // Update localStorage when colorMode changes
    localStorage.setItem('theme', colorMode);
    
    // Update document with the appropriate class for Tailwind theme
    const root = window.document.documentElement;
    if (colorMode === 'dark') {
      root.classList.remove('light');
      root.classList.add('dark');
      root.setAttribute('data-theme', 'dark');
      document.body.classList.remove('light');
      document.body.classList.add('dark');
    } else {
      root.classList.remove('dark');
      root.classList.add('light');
      root.setAttribute('data-theme', 'light');
      document.body.classList.remove('dark');
      document.body.classList.add('light');
    }
  }, [colorMode, isInitialized]);

  const toggleTheme = () => {
    toggleColorMode();
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const RootThemeProvider = ({ children }) => {
  return (
    <ChakraProvider theme={theme} initialColorMode="dark">
      <ThemeProvider>{children}</ThemeProvider>
    </ChakraProvider>
  );
}; 