import React from 'react';
import { 
  Box, 
  Container, 
  Flex, 
  Text, 
  Link, 
  IconButton, 
  VStack, 
  HStack, 
  Divider,
  useColorModeValue,
  Image
} from '@chakra-ui/react';
import { FiGithub, FiLinkedin, FiMail, FiArrowUp } from 'react-icons/fi';
import { Link as ScrollLink } from 'react-scroll';
import { useTheme } from './ThemeProvider';

const Footer = () => {
  const { theme } = useTheme();
  const logo = theme === 'light' ? '/LogoLight.png' : '/LogoDark.png';
  
  // Color mode values
  const bgColor = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('gray.200', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const headingColor = useColorModeValue('gray.800', 'white');
  const linkColor = useColorModeValue('gray.600', 'gray.400');
  const linkHoverColor = useColorModeValue('blue.600', 'blue.400');
  const iconBgColor = useColorModeValue('gray.100', 'gray.800');
  const iconHoverBgColor = useColorModeValue('gray.200', 'gray.700');

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const currentYear = new Date().getFullYear();

  return (
    <Box 
      as="footer" 
      bg={bgColor} 
      borderTop="1px" 
      borderColor={borderColor}
      py={10}
      position="relative"
    >
      {/* Background gradient accent */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        height="6px"
        bgGradient="linear(to-r, blue.500, teal.400)"
        opacity={0.8}
      />
      
      <Container maxW="7xl">
        <Flex 
          direction={{ base: 'column', md: 'row' }} 
          justify="space-between" 
          align={{ base: 'center', md: 'flex-start' }}
          gap={8}
        >
          {/* Logo and description */}
          <VStack align={{ base: 'center', md: 'flex-start' }} spacing={4} maxW="md">
            <Flex align="center" gap={3}>
              <Image src={logo} alt="Logo" height="40px" />
            </Flex>
            <Text color={textColor} fontSize="sm" textAlign={{ base: 'center', md: 'left' }}>
              Software developer specializing in creating innovative solutions with modern technologies.
              Building web and mobile applications with a focus on user experience and performance.
            </Text>
            
            {/* Social links */}
            <HStack spacing={3} mt={2}>
              <Link href="https://github.com/bardanp" isExternal>
                <IconButton
                  aria-label="GitHub"
                  icon={<FiGithub />}
                  size="md"
                  variant="ghost"
                  color={linkColor}
                  bg={iconBgColor}
                  _hover={{ 
                    bg: iconHoverBgColor,
                    color: linkHoverColor,
                    transform: 'translateY(-2px)'
                  }}
                  borderRadius="full"
                  transition="all 0.3s ease"
                />
              </Link>
              <Link href="https://linkedin.com/in/bardanphuyel" isExternal>
                <IconButton
                  aria-label="LinkedIn"
                  icon={<FiLinkedin />}
                  size="md"
                  variant="ghost"
                  color={linkColor}
                  bg={iconBgColor}
                  _hover={{ 
                    bg: iconHoverBgColor,
                    color: linkHoverColor,
                    transform: 'translateY(-2px)'
                  }}
                  borderRadius="full"
                  transition="all 0.3s ease"
                />
              </Link>
              <Link href="mailto:contact@bardanp.com">
                <IconButton
                  aria-label="Email"
                  icon={<FiMail />}
                  size="md"
                  variant="ghost"
                  color={linkColor}
                  bg={iconBgColor}
                  _hover={{ 
                    bg: iconHoverBgColor,
                    color: linkHoverColor,
                    transform: 'translateY(-2px)'
                  }}
                  borderRadius="full"
                  transition="all 0.3s ease"
                />
              </Link>
            </HStack>
          </VStack>
          
          {/* Quick links */}
          <VStack align={{ base: 'center', md: 'flex-start' }} spacing={4}>
            <Text fontWeight="bold" fontSize="md" color={headingColor}>Quick Links</Text>
            <VStack align={{ base: 'center', md: 'flex-start' }} spacing={2}>
              <ScrollLink to="aboutme" smooth={true} duration={500}>
                <Link 
                  color={linkColor} 
                  _hover={{ color: linkHoverColor, textDecoration: 'none' }}
                  fontSize="sm"
                >
                  About Me
                </Link>
              </ScrollLink>
              <ScrollLink to="projects" smooth={true} duration={500}>
                <Link 
                  color={linkColor} 
                  _hover={{ color: linkHoverColor, textDecoration: 'none' }}
                  fontSize="sm"
                >
                  Projects
                </Link>
              </ScrollLink>
              <ScrollLink to="contact" smooth={true} duration={500}>
                <Link 
                  color={linkColor} 
                  _hover={{ color: linkHoverColor, textDecoration: 'none' }}
                  fontSize="sm"
                >
                  Contact
                </Link>
              </ScrollLink>
            </VStack>
          </VStack>
          
          {/* Back to top button */}
          <Box>
            <IconButton
              aria-label="Back to top"
              icon={<FiArrowUp />}
              size="lg"
              isRound
              onClick={scrollToTop}
              bg={useColorModeValue('blue.500', 'blue.600')}
              color="white"
              _hover={{ 
                bg: useColorModeValue('blue.600', 'blue.500'),
                transform: 'translateY(-4px)',
                boxShadow: 'lg'
              }}
              transition="all 0.3s ease"
              boxShadow="md"
            />
          </Box>
        </Flex>
        
        <Divider my={8} borderColor={borderColor} />
        
        {/* Copyright */}
        <Flex 
          direction={{ base: 'column', sm: 'row' }} 
          justify="space-between" 
          align="center"
          gap={4}
        >
          <Text color={textColor} fontSize="sm">
            © {currentYear} Bardan. All rights reserved.
          </Text>
          <Text color={textColor} fontSize="xs">
            Built with React, Chakra UI, and Tailwind CSS
          </Text>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer; 