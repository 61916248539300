import React from 'react';
import {
  Box,
  Heading,
  VStack,
  Link,
  Icon,
  SimpleGrid,
  Text,
  Flex,
  Badge,
  HStack,
  useColorModeValue
} from '@chakra-ui/react';
import { FiGithub, FiMail, FiLinkedin, FiMessageSquare } from 'react-icons/fi';
import { Element } from 'react-scroll';

const Connect = () => {
  const contactMethods = [
    {
      icon: FiGithub,
      title: 'GitHub',
      description: 'Check out my code repositories',
      link: 'https://github.com/bardanp',
      color: 'purple'
    },
    {
      icon: FiMail,
      title: 'Email',
      description: 'me@bardanp.com',
      link: 'mailto:me@bardanp.com',
      color: 'teal'
    },
    {
      icon: FiLinkedin,
      title: 'LinkedIn',
      description: 'Connect professionally',
      link: 'https://www.linkedin.com/in/bardanp',
      color: 'blue'
    }
  ];

  // Badge colors
  const badgeBg = useColorModeValue("blue.100", "blue.900/20");
  const badgeColor = useColorModeValue("blue.700", "blue.400");
  const badgeBorder = useColorModeValue("blue.300", "blue.800");
  const textColor = useColorModeValue("gray.700", "gray.300");
  
  // Card colors - defined outside the map function
  const cardBg = useColorModeValue('white', 'gray.900/50');
  const cardShadow = useColorModeValue('0 4px 12px rgba(0, 0, 0, 0.05)', 'md');
  const hoverShadow = useColorModeValue('0 8px 24px rgba(0, 0, 0, 0.1)', 'lg');
  const titleColor = useColorModeValue('gray.800', 'white');
  const descColor = useColorModeValue('gray.600', 'gray.300');
  
  // Color-specific values
  const blueBorder = useColorModeValue('blue.200', 'blue.800');
  const blueHoverBorder = useColorModeValue('blue.300', 'blue.700');
  const blueIconBg = useColorModeValue('blue.100', 'blue.900/30');
  const blueIconColor = useColorModeValue('blue.700', 'blue.400');
  
  const tealBorder = useColorModeValue('teal.200', 'teal.800');
  const tealHoverBorder = useColorModeValue('teal.300', 'teal.700');
  const tealIconBg = useColorModeValue('teal.100', 'teal.900/30');
  const tealIconColor = useColorModeValue('teal.700', 'teal.400');
  
  const purpleBorder = useColorModeValue('purple.200', 'purple.800');
  const purpleHoverBorder = useColorModeValue('purple.300', 'purple.700');
  const purpleIconBg = useColorModeValue('purple.100', 'purple.900/30');
  const purpleIconColor = useColorModeValue('purple.700', 'purple.400');

  // Function to get color values based on color name
  const getColorValues = (colorName) => {
    switch(colorName) {
      case 'teal':
        return {
          cardBorder: tealBorder,
          cardHoverBorder: tealHoverBorder,
          iconBg: tealIconBg,
          iconColor: tealIconColor
        };
      case 'purple':
        return {
          cardBorder: purpleBorder,
          cardHoverBorder: purpleHoverBorder,
          iconBg: purpleIconBg,
          iconColor: purpleIconColor
        };
      default: // blue
        return {
          cardBorder: blueBorder,
          cardHoverBorder: blueHoverBorder,
          iconBg: blueIconBg,
          iconColor: blueIconColor
        };
    }
  };

  return (
    <Element name="contact">
      <Box 
        w="100%" 
        data-aos="fade-up"
      >
        <Box textAlign="center" mb={10}>
          <Badge 
            bg={badgeBg}
            color={badgeColor}
            px={6}
            py={2.5}
            borderRadius="full"
            fontSize="lg"
            textTransform="none"
            fontWeight="bold"
            borderWidth="1px"
            borderColor={badgeBorder}
            display="inline-flex"
            alignItems="center"
            mb={3}
            boxShadow="sm"
          >
            <Icon as={FiMessageSquare} boxSize={5} mr={3} />
            Connect With Me
          </Badge>
          
          <Text
            color={textColor}
            maxW="2xl"
            mx="auto"
            fontSize="md"
          >
            I'm always open to new opportunities and collaborations
          </Text>
        </Box>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} px={{ base: 0, md: 4 }}>
          {contactMethods.map((method, index) => {
            // Get color values based on the method color
            const colorValues = getColorValues(method.color);
            
            return (
              <Link 
                key={index} 
                href={method.link} 
                isExternal 
                _hover={{ textDecoration: 'none' }}
              >
                <Box 
                  p={6} 
                  borderRadius="lg"
                  bg={cardBg}
                  borderWidth="1px"
                  borderColor={colorValues.cardBorder}
                  boxShadow={cardShadow}
                  transition="all 0.3s"
                  _hover={{
                    transform: 'translateY(-5px)',
                    borderColor: colorValues.cardHoverBorder,
                    boxShadow: hoverShadow,
                  }}
                  height="100%"
                >
                  <VStack align="start" spacing={4}>
                    <Flex
                      alignItems="center" 
                      justifyContent="center"
                      w="40px"
                      h="40px"
                      bg={colorValues.iconBg}
                      color={colorValues.iconColor}
                      borderRadius="lg"
                      borderWidth="1px"
                      borderColor={colorValues.cardHoverBorder}
                    >
                      <Icon as={method.icon} boxSize={5} />
                    </Flex>
                    
                    <Heading as="h3" size="md" color={titleColor} fontWeight="semibold">
                      {method.title}
                    </Heading>
                    
                    <Text color={descColor}>
                      {method.description}
                    </Text>
                  </VStack>
                </Box>
              </Link>
            );
          })}
        </SimpleGrid>
        
        <HStack justify="center" mt={16} mb={8} spacing={2}>
          <Text color="gray.500" fontSize="sm">© {new Date().getFullYear()}</Text>
          <Text color="gray.300" fontSize="sm">Bardan</Text>
        </HStack>
      </Box>
    </Element>
  );
};

export default Connect;