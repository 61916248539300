import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  VStack,
  Container,
  HStack,
  IconButton,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react';
import { 
  FiGithub, 
  FiLinkedin, 
  FiDownload, 
  FiUser
} from 'react-icons/fi';
import { Element } from 'react-scroll';
import Connect from '../pages/Connect';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LoadingState from '../components/LoadingState';
import TypedText from '../components/TypedText';
import SkillsSection from '../components/SkillsSection';
import ExperienceSection from '../components/ExperienceSection';
import ProjectsSection from '../components/ProjectsSection';

const resumeURL = './Resume.pdf';

const projects = [
  {
    title: 'Clarify Task Management App',
    description: 'Developed a productivity-focused task management app, Clarify, using React Native for mobile platforms to help users organize tasks effectively.',
    imageUrls: [
      'https://clarify.bardanp.com/images/img1.jpeg',
      'https://clarify.bardanp.com/images/img2.jpeg',
      'https://clarify.bardanp.com/images/img3.jpeg',
      'https://clarify.bardanp.com/images/img4.jpeg',
      'https://clarify.bardanp.com/images/img5.jpeg',
    ],
    link: 'https://clarify.bardanp.com',
    github: 'https://github.com/bardanp/Clarify',
    techStack: 'React Native, Firebase, Firestore',
    whatILearned: 'Real-time data synchronization and mobile app development.',
    projectInfo: 'Task management with custom themes, notifications, and a sleek user interface.'
  },
  {
    title: 'Nittany Navigator',
    description: 'A campus navigation app developed for Pennsylvania State University students and faculty, allowing them to create events and reports.',
    imageUrls: [
      '/projectImg/nittanyNavigator1.png',
      '/projectImg/nittanyNavigator2.png',
      '/projectImg/nittanyNavigator3.png',
      '/projectImg/nittanyNavigator4.png',
      '/projectImg/nittanyNavigator5.png',
      '/projectImg/nittanyNavigator6.png',
    ],
    link: '',
    github: 'http://nittany-navigator.bardanp.com',
    techStack: 'React Native, Firebase, Node.js, Azure SSO',
    whatILearned: 'Advanced React Native development and Azure integrations.',
    projectInfo: 'Campus navigation with event tracking, location services, and safety features.'
  },
  {
    title: 'AmazonScout API',
    description: 'Developed a robust API that fetches product details, reviews, offers, and search results from Amazon using the ScraperAPI. Deployed on Vercel for seamless accessibility.',
    imageUrls: [
      '/projectImg/amazonScout1.png',
      '/projectImg/amazonScout2.png',
    ],
    link: 'https://amazonscout.bardanp.com',
    github: 'https://github.com/bardanp/AmazonScout',
    techStack: 'Node.js, Express.js, request-promise',
    whatILearned: 'API development, web scraping, and deployment with Vercel.',
    projectInfo: 'Fetches comprehensive Amazon product data using ScraperAPI for e-commerce integration.'
  },
  {
    title: 'Movie Search App',
    description: 'An interactive web app for movie searches, providing users with the ability to search for movies by title. The application utilizes the OMDB API to fetch movie data in real-time.',
    imageUrls: [
      '/projectImg/movieSearchApp1.png',
      '/projectImg/movieSearchApp2.png',
    ],
    link: 'http://movie-app.bardanp.com',
    github: 'https://github.com/bardanp/Movie-App',
    techStack: 'React.js, JavaScript, OMDB API',
    whatILearned: 'API integration, dynamic search functionality, and real-time data management.',
    projectInfo: 'Search functionality with a clean and responsive UI for movie enthusiasts.'
  },
  {
    title: 'Notes Web App',
    description: 'A comprehensive note-taking platform developed as a project for the 487W Software Engineering course. The application allows users to manage notes with features like adding, editing, and deleting notes.',
    imageUrls: [
      '/projectImg/notesWebApp1.png',
      '/projectImg/notesWebApp2.png',
      '/projectImg/notesWebApp3.png',
    ],
    link: '',
    github: 'http://notes-app.bardanp.com',
    techStack: 'Flask, HTML, CSS, JavaScript, Firebase',
    whatILearned: 'Full-stack development, multimedia attachment handling, and responsive design.',
    projectInfo: 'Enhanced note management system with multimedia support for efficient organization.'
  }
];

// Additional information from resume
const aboutMeText = `I'm a Computer Science major from Penn State University with a passion for creating innovative software solutions. 
I specialize in full-stack development using React, React Native, Node.js, and various cloud technologies. 
My experience includes developing web and mobile applications, implementing RESTful APIs, 
and integrating with various authentication systems and databases.`;

const About = () => {
  const [isLoading, setIsLoading] = useState(true);
  
  // Define all color mode values at the top level
  const bgColor = useColorModeValue('gray.50', 'gray.950');
  const textColor = useColorModeValue('gray.700', 'gray.300');
  const headingGradient = useColorModeValue(
    'linear(to-r, blue.600, teal.600)',
    'linear(to-r, blue.400, teal.400)'
  );
  const bgGradient = useColorModeValue(
    'linear(to-br, gray.100, gray.50)',
    'linear(to-br, gray.900, gray.950)'
  );
  const gridBackground = useColorModeValue(
    'radial-gradient(circle at 25px 25px, gray.300 2%, transparent 0%), radial-gradient(circle at 75px 75px, gray.300 2%, transparent 0%)',
    'radial-gradient(circle at 25px 25px, gray.800 2%, transparent 0%), radial-gradient(circle at 75px 75px, gray.800 2%, transparent 0%)'
  );
  const accentBg1 = useColorModeValue('blue.100', 'blue.900');
  const accentOpacity1 = useColorModeValue('0.1', '0.15');
  const accentBg2 = useColorModeValue('teal.100', 'teal.900');
  const cardBg = useColorModeValue('white', 'gray.900/50');
  const cardBorder = useColorModeValue('gray.300', 'gray.800');
  const cardShadow = useColorModeValue('sm', 'md');
  const badgeBg = useColorModeValue('blue.100', 'blue.900/20');
  const badgeColor = useColorModeValue('blue.700', 'blue.400');
  const badgeBorder = useColorModeValue('blue.300', 'blue.800');
  const buttonBorderColor = useColorModeValue('gray.300', 'gray.700');
  const buttonHoverBorderColor = useColorModeValue('blue.400', 'blue.400');
  const buttonHoverColor = useColorModeValue('blue.700', 'blue.400');
  const dividerColor = useColorModeValue('gray.300', 'gray.800');

  useEffect(() => {
    AOS.init({ 
      duration: 1000,
      easing: 'ease-out-cubic',
      once: true 
    });
    setTimeout(() => setIsLoading(false), 1500);
  }, []);

  if (isLoading) return <LoadingState />;

  return (
    <Box position="relative" minHeight="100vh" pt="90px" bg={bgColor}>
      {/* Background elements */}
      <Box 
        position="absolute" 
        top="0" 
        left="0" 
        right="0" 
        height="100vh" 
        zIndex="0"
        bgGradient={bgGradient}
        overflow="hidden"
      >
        {/* Abstract grid background */}
        <Box 
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          opacity="0.07"
          background={gridBackground}
          backgroundSize="100px 100px"
        />
        
        {/* Gradient accent */}
        <Box
          position="absolute"
          top="-50%"
          right="-20%"
          width="70%"
          height="100%"
          bg={accentBg1}
          filter="blur(150px)"
          opacity={accentOpacity1}
          borderRadius="full"
        />
        
        <Box
          position="absolute"
          top="40%"
          left="-10%"
          width="40%"
          height="60%"
          bg={accentBg2}
          filter="blur(150px)"
          opacity="0.1"
          borderRadius="full"
        />
      </Box>

      <Container maxW="7xl" py={8} position="relative" zIndex="1">
        <Element name="aboutme">
          <Box px={{ base: 4, md: 8 }} mb={20}>
            <Box 
              borderRadius="xl"
              bg={cardBg}
              backdropFilter="blur(12px)"
              borderWidth="1px"
              borderColor={cardBorder}
              p={{ base: 6, md: 10 }}
              position="relative"
              overflow="hidden"
              boxShadow={cardShadow}
              data-aos="fade-up"
            >
              {/* Gradient accents */}
              <Box
                position="absolute"
                top="-100px"
                right="-100px"
                width="200px"
                height="200px"
                bg="linear-gradient(90deg, var(--chakra-colors-blue-600), var(--chakra-colors-teal-400))"
                filter="blur(80px)"
                opacity="0.15"
                borderRadius="full"
              />
              
              <VStack spacing={8} align="center" position="relative" zIndex="1">
                <Box
                  bg={badgeBg}
                  color={badgeColor}
                  px={4}
                  py={2}
                  borderRadius="full"
                  fontSize="sm"
                  fontWeight="medium"
                  borderWidth="1px"
                  borderColor={badgeBorder}
                  data-aos="fade-down"
                  data-aos-delay="300"
                  display="flex"
                  alignItems="center"
                  boxShadow="sm"
                >
                  <FiUser style={{ marginRight: '8px' }} />
                  Software Developer
                </Box>
                
                <Heading 
                  as="h1" 
                  fontSize={{ base: "4xl", md: "5xl" }}
                  bgGradient={headingGradient}
                  bgClip="text"
                  letterSpacing="tight"
                  fontWeight="bold"
                  textAlign="center"
                  data-aos="fade-up"
                >
                  Hey, I'm Bardan
                </Heading>
                
                <Text 
                  fontSize={{ base: "lg", md: "xl" }}
                  color={textColor}
                  textAlign="center"
                  maxW="2xl"
                  lineHeight="tall"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <TypedText 
                    strings={[
                      "I build web applications.",
                      "I develop mobile apps.",
                      "I create user experiences.",
                      "I solve problems with code."
                    ]} 
                  />
                </Text>
                
                <Text 
                  fontSize={{ base: "md", md: "lg" }}
                  color={textColor}
                  textAlign="center"
                  maxW="2xl"
                  lineHeight="tall"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  {aboutMeText}
                </Text>
                
                <HStack 
                  spacing={4} 
                  mt={4}
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <Button
                    leftIcon={<FiDownload />}
                    onClick={() => window.open(resumeURL, '_blank')}
                    size="lg"
                    bgGradient={headingGradient}
                    color="white"
                    _hover={{
                      transform: 'translateY(-2px)',
                      boxShadow: 'lg',
                    }}
                    transition="all 0.3s"
                  >
                    Download Resume
                  </Button>
                  
                  <HStack spacing={2}>
                    <IconButton
                      aria-label="GitHub"
                      icon={<FiGithub />}
                      onClick={() => window.open('https://github.com/bardanp', '_blank')}
                      size="lg"
                      variant="outline"
                      borderColor={buttonBorderColor}
                      _hover={{
                        transform: 'translateY(-2px)',
                        borderColor: buttonHoverBorderColor,
                        color: buttonHoverColor,
                      }}
                      transition="all 0.3s"
                    />
                    
                    <IconButton
                      aria-label="LinkedIn"
                      icon={<FiLinkedin />}
                      onClick={() => window.open('https://linkedin.com/in/bardanphuyel', '_blank')}
                      size="lg"
                      variant="outline"
                      borderColor={buttonBorderColor}
                      _hover={{
                        transform: 'translateY(-2px)',
                        borderColor: buttonHoverBorderColor,
                        color: buttonHoverColor,
                      }}
                      transition="all 0.3s"
                    />
                  </HStack>
                </HStack>
              </VStack>
            </Box>
          </Box>
        </Element>

        <SkillsSection />
        
        <ExperienceSection />

        <Element name="projects">
          <ProjectsSection projects={projects} />
        </Element>

        <Divider borderColor={dividerColor} opacity={0.5} my={16} />

        <Connect />
      </Container>
    </Box>
  );
};

export default About;
